<template>
    <div style="display: flex;">
        <div class="dropdown-menu-button" @click="switchMenu" v-click-outside="hideMenu">
            <div><slot></slot></div>
            <div class="dropdown-menu-body" :class="[shown ? 'active' : '', { 'pos-top': showOnTop }]" @click.stop>
                <div class="dropdown-menu-content" :class="[shown ? 'active' : loaded ? 'hidden' : '']">
                    <div class="title" v-if="title">
                        <h2>{{ title }}</h2>
                    </div>
                    <div>
                        <button :key="key" v-for="(opt, key) of options" class="option"
                            :class="[{ 'remove': opt.type && opt.type === 'remove' }]" @click="emitSelected(opt)">{{
                                opt.name }}</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        icon: { type: String },
        options: { type: Array },
        title: { type: String },
        showOnTop: { type: Boolean },
    },
    data() {
        return {
            _icon: this.icon || 'fa-solid fa-ellipsis-vertical',
            shown: false,
            loaded: false
        }
    },
    methods: {
        switchMenu() {
            this.shown = !this.shown;
            if (this.shown && !this.loaded) {
                this.loaded = true;
            }
        },
        hideMenu() {
            this.shown = false;
        },
        emitSelected(option) {
            if (option.emit) this.$emit(option.emit, option.emitValue || null);
            this.hideMenu();
        }
    },
    watch: {
        shown() {
            if (this.shown) this.$emit('show');
            else this.$emit('hide');
        }
    }
}
</script>

<style lang="scss" scoped>
@keyframes popUpAnimation {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}


@keyframes popUpAnimationReverse {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(0);
    }
}

.dropdown-menu-button {
    position: relative;
    user-select: none;
}

.dropdown-menu-body {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateY(10px) translateX(-50%);
    pointer-events: none;

    &.pos-top {
        top: auto;
        bottom: 100%;
        transform: translateY(-10px) translateX(-50%);
    }

    &.active {
        pointer-events: auto;
    }
}


.dropdown-menu-content {
    background: $themeColor1;
    border-radius: 10px;
    min-width: 150px;
    transform: scale(0);
    pointer-events: none;
    transition: ease 0.1s;
    box-shadow: $boxShadow;
    border: 1px solid $borderColor;
    overflow: hidden;
    opacity: 0;
    cursor: auto;

    .title {
        color: $textLight;
        padding: 10px 10px;
        border-bottom: 1px solid $borderColor;

        h2 {
            font-size: 14px;
            font-weight: 500;
        }
    }

    &.hidden {
        pointer-events: none;
        animation: popUpAnimationReverse 0.15s;
        animation-fill-mode: forwards;
    }

    &.active {
        pointer-events: auto;
        animation: popUpAnimation 0.2s;
        animation-fill-mode: forwards;
    }

    .option {
        min-height: 34px;
        border: 0;
        border-bottom: 1px solid $borderColor;
        width: 100%;
        background: transparent;
        text-align: left;
        padding: 5px 10px;
        font-size: 12px;
        cursor: pointer;
        transition: ease 0.3s;

        &:hover {
            color: $themeColor2;
        }

        &.remove {
            color: $error;
        }
    }
}
</style>