<template>
    <div class="lds-ring" :class="[ 'spinner-' + size ]"><div></div><div></div><div></div><div></div></div>
</template>

<script>
export default {
    props: ['size'],
}
</script>

<style lang="scss" scoped>
.lds-ring {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        border: 6px solid $themeColor2;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: $themeColor2 transparent transparent transparent;
    }

    div:nth-child(1) {
        animation-delay: -0.45s;
    }

    div:nth-child(2) {
        animation-delay: -0.3s;
    }

    div:nth-child(3) {
        animation-delay: -0.15s;
    }

    &.spinner-small {
        width: 25px;
        height: 25px;
        div {
            border-width: 3px;
        }
    }

    &.spinner-xs {
        width: 13px;
        height: 13px;
        div {
            border-width: 2px;
        }
    }


}


@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>